.error-boundary-container {
    height: 100vh;
    width: 100%;
    display: grid;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.error-boundary {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 0.25em;
    padding-left: 3em;
}

.error-boundary-actions {
    display: flex;
    column-gap: 1em;
}

.error-boundary-button {
    background-color: hsl(191, 50%, 26%);
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    min-width: 150px;
    border-radius: 5px;
    user-select: none;
    cursor: pointer;
}

.error-boundary-actions .secondary {
    background: hsl(191, 41%, 48%)
}

.error-boundary-message {
    word-wrap: break-word;
    box-decoration-break: clone;
    padding: 0.25rem 0.5rem;
    font-weight: 100;
    margin-top: 2em;
    margin-bottom: 2em;
    user-select: all;
}


.error-boundary-container h1 {
    font-weight: 200;
    user-select: none;

}

.error-boundary-container h2 {
    font-weight: 400;
    user-select: none;
}
